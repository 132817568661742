import React, { useState } from 'react'
import {
  AutocompleteArrayInput,
  BooleanInput,
  Create,
  email,
  FormDataConsumer,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  SimpleForm,
  regex,
  TextInput,
  Toolbar,
  SaveButton,
  translate,
} from 'react-admin'
import { Button, Snackbar } from '@material-ui/core';
import { ColorInput } from 'react-admin-color-input'
import { MODULE_IDS } from '../../../../config/api'
import PropTypes from "prop-types";
import compose from 'recompose/compose'
import { WhatsAppSenderTestCallback } from '../common/whatsapp';

const ClientsCreate = (props) => {
  const [count, setCount] = useState(0);
  const [submitIsDisabled, setSubmitIsDisabled] = useState(false);
  const [whatsappTestSnackbar, setWhatsappTestSnackbar] = useState({});
  const whatsAppPhoneFieldId = 'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_test_phone';

  const PostCreateToolbar = props => (
    <Toolbar {...props} >
        <SaveButton
            redirect="show"
            submitOnEnter={false}
            disabled={ submitIsDisabled }
        />
    </Toolbar>
  );

  const sendWhatsAppTest = async () => {
    setSubmitIsDisabled(true);
    let lang = document.getElementById('lang').value;
    let whatsappSenderId = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id').value;
    let wabaKey = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key').value;
    let whatsappTemplate = document.getElementById('module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template').value;
    let whatsappTo = document.getElementById(whatsAppPhoneFieldId).value;
  
    const launchTest = await WhatsAppSenderTestCallback(() => {
      return {
        whatsappSenderId: whatsappSenderId,
        wabaKey: wabaKey,
        whatsappTemplate: whatsappTemplate,
        whatsappTo: whatsappTo,
        lang: lang
      };
    });
    if (launchTest) {
      setSubmitIsDisabled(false);
      setWhatsappTestSnackbar({message: props.translate('whatsapp.test.success'), type: 'success'});
    } else {
      setWhatsappTestSnackbar({message: props.translate('whatsapp.test.error'), type: 'error'});
    }
    
    setTimeout(() => {
      setWhatsappTestSnackbar({});
    }, 3000);
  };


  return (
    <Create {...props}>
      <SimpleForm toolbar={<PostCreateToolbar />} redirect="show">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput source="email_from" validate={[required(), email()]} />
        <SelectInput
          source="lang"
          choices={[
            { id: 'es', name: 'Español' },
            { id: 'en', name: 'Ingles' },
            { id: 'pt', name: 'Portugués' },
          ]}
        />
        <BooleanInput source="sms_available" />
        <TextInput source="sms_prefix" validate={regex(/^\+.+/)} />
        <TextInput source="sendgrid_token" />

        <AutocompleteArrayInput source="features" choices={[
          { id: 'push_offer', name: 'Push offers' },
          { id: 'doc_request', name: 'Doc request' },
        ]} />

        <NumberInput source="minimum_percentage_suitable" validate={[required()]} />
        <NumberInput source="skill_weight" validate={[required()]} />
        <NumberInput source="profile_weight" validate={[required()]} />

        <ColorInput source="text_color_principal" validate={[required()]} defaultValue="#000000" />
        <ColorInput source="text_color_secondary" validate={[required()]} defaultValue="#000000" />
        <ColorInput source="background_color" validate={[required()]} defaultValue="#FFFFFF" />

        <ImageInput source="logo" accept="image/*">
          <ImageField source="imageBase64" title="title" multiple={false} />
        </ImageInput>

        <Separator />

        <BooleanInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData['module_' + MODULE_IDS.ID_TALENT_ACQUISITION] &&
              formData['module_' + MODULE_IDS.ID_TALENT_ACQUISITION].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.inbox_fields'}
                    perPage={100}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_TALENT_ACQUISITION }}
                    filterToQuery={(searchText) => ({ name: searchText, 'moduleType.id': MODULE_IDS.ID_TALENT_ACQUISITION })}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                  </ReferenceArrayInput>

                  <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.minimum_percentage_suitable'} validate={[required()]} />
                  <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.skill_weight'} validate={[required()]} />
                  <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.profile_weight'} validate={[required()]} />

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_repush_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_starter_recurrent_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_bot_feedback_template'} />

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_chat_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_citation_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.token_sendgrid_email_notice_template'} label="resources.clients.fields.module_talent_acquisition.token_sendgrid_email_notice_template" />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                  <TextInput
                    source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.schedules_available_to_notify_bot_starter'}
                    label="resources.clients.fields.module_talent_acquisition.schedules_available_to_notify_bot_starter"
                    helperText={props.translate("resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper")}
                  />

                  <TextInput
                    {...rest}
                    fullWidth
                    multiline
                    label="resources.clients.fields.module_talent_acquisition.sms_start_bot_template"
                    source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_start_bot_template'}
                    inputProps={{maxLength: 140}}
                    helperText={props.translate("resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper")}
                  />

                  <TextInput
                      {...rest}
                      fullWidth
                      multiline
                      label="resources.clients.fields.module_talent_acquisition.sms_repush_bot_template"
                      source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_repush_bot_template'}
                      inputProps={{maxLength: 140}}
                  />

                  <TextInput
                      {...rest}
                      fullWidth
                      multiline
                      label="resources.clients.fields.module_talent_acquisition.sms_bot_starter_recurrent_template"
                      source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_bot_starter_recurrent_template'}
                      inputProps={{maxLength: 140}}
                  />

                  <TextInput
                      {...rest}
                      fullWidth
                      multiline
                      label="resources.clients.fields.module_talent_acquisition.sms_feedback_bot_template"
                      source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.sms_feedback_bot_template'}
                      inputProps={{maxLength: 140}}
                  />


                  <NumberInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_in_days'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.update_candidature_bot_url'} validate={[required(), regex(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/)]} />

                  <BooleanInput {...rest} source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.talent_clue_integrated'} />

                </div>
              )
            )
          }}
        </FormDataConsumer>

        <Separator />

        <BooleanInput source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData['module_' + MODULE_IDS.ID_EMPLOYEE_LINE] &&
              formData['module_' + MODULE_IDS.ID_EMPLOYEE_LINE].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.inbox_fields'}
                    perPage={100}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_EMPLOYEE_LINE }}
                    filterToQuery={(searchText) => ({ name: searchText, 'moduleType.id': MODULE_IDS.ID_EMPLOYEE_LINE })}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                  </ReferenceArrayInput>

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_new_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.sendgrid_process_status_changed_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                </div>
              )
            )
          }}
        </FormDataConsumer>
        <Separator />

        <BooleanInput source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData['module_' + MODULE_IDS.ID_ON_BOARDING] &&
              formData['module_' + MODULE_IDS.ID_ON_BOARDING].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.inbox_fields'}
                    perPage={100}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_ON_BOARDING }}
                    filterToQuery={(searchText) => ({ name: searchText, 'moduleType.id': MODULE_IDS.ID_ON_BOARDING })}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                  </ReferenceArrayInput>

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_new_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.sendgrid_process_status_changed_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                </div>
              )
            )
          }}
        </FormDataConsumer>

        <BooleanInput source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData['module_' + MODULE_IDS.ID_FEEDBACK] &&
              formData['module_' + MODULE_IDS.ID_FEEDBACK].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_FEEDBACK + '.inbox_fields'}
                    perPage={100}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_FEEDBACK }}
                    filterToQuery={(searchText) => ({ name: searchText, 'moduleType.id': MODULE_IDS.ID_FEEDBACK })}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                  </ReferenceArrayInput>

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.sendgrid_feedback_new_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_FEEDBACK + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                </div>
              )
            )
          }}
        </FormDataConsumer>

        <BooleanInput source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
        <FormDataConsumer>
          {({ formData, ...rest }) => {
            return (
              formData['module_' + MODULE_IDS.ID_EXIT_INTERVIEW] &&
              formData['module_' + MODULE_IDS.ID_EXIT_INTERVIEW].enabled && (
                <div className="child-items">
                  <ReferenceArrayInput
                    {...rest}
                    reference="inboxes"
                    source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.inbox_fields'}
                    perPage={100}
                    key={`inboxes_${count}`}
                    filter={{ 'moduleType.id': MODULE_IDS.ID_EXIT_INTERVIEW }}
                    filterToQuery={(searchText) => ({ name: searchText, 'moduleType.id': MODULE_IDS.ID_EXIT_INTERVIEW })}
                    onChange={() => setTimeout(() => setCount(count + 1), 250)}
                  >
                    <AutocompleteArrayInput optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
                  </ReferenceArrayInput>

                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.sendgrid_new_exit_interview_template'} />
                  <TextInput {...rest} source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.stats_filters_available'} label="resources.clients.fields.stats_filters_available" />
                </div>
              )
            )
          }}
        </FormDataConsumer>
        <Separator />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_sender_id'} />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.waba_key'} />
        <TextInput source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_template'} />
        <span>
          <TextInput 
            label={props.translate('resources.clients.fields.module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.whatsapp_test_phone')}
            id={whatsAppPhoneFieldId}
          /> &nbsp;
          <Button variant="outlined" color="secondary" size="small" onClick={ sendWhatsAppTest }>Test WhatsApp</Button>
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            open={whatsappTestSnackbar.message}
            autoHideDuration={3000}
            message={whatsappTestSnackbar.message}
            >
          </Snackbar>
        </span>
      </SimpleForm>
    </Create>
  )
}

const Separator = () => <div style={{ height: 5 }} />

ClientsCreate.propTypes = {
  translate: PropTypes.func.isRequired,
}

const enhance = compose(translate)

export default enhance(ClientsCreate)