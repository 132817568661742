import React from 'react'
import { ReferenceField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'

const FiltersShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="remote_name" />
        <ReferenceField source="client.id" reference="clients" allowEmpty>
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="candidature.id" reference="candidatures" allowEmpty label="Candidatura">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <TextField source="order" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default FiltersShow
