import React, { Fragment } from 'react'
import { change } from 'redux-form';
import {
  Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, FormDataConsumer,
  REDUX_FORM_NAME
} from 'react-admin'
import { FILTER_TYPES_CHOICES } from "../../../../config/api";

const FiltersEdit = props => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <TextInput source="remote_name" />
        <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData, dispatch, ...rest }) => (
            <Fragment>
              <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                sort={{ field: 'name', order: 'ASC' }} perPage={1000}
                filterToQuery={(searchText) => ({ name: searchText })}
                onChange={value => dispatch(
                  change(REDUX_FORM_NAME, 'candidature_config.id', null)
                )}
                {...rest}
              >
                <SelectInput source="name" />
              </ReferenceInput>
              <ReferenceInput
                source='candidature.id'
                reference="candidatures"
                allowEmpty
                label="Candidatura"
                filter={{ 'client.id': formData.client?.id }}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Fragment>
          )}
        </FormDataConsumer>
        <SelectInput source="type" choices={FILTER_TYPES_CHOICES} />
        <NumberInput source="order" />

      </SimpleForm>
    </Edit>
  )
}

export default FiltersEdit
